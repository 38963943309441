<template>
  <div class="app-container">
    <div class="filter-container">
      <el-radio-group v-model="listQuery.order_type" class="filter-item" size="small" @change="handleFilter">
        <el-radio-button :label="1">外卖</el-radio-button>
        <el-radio-button :label="2">商城</el-radio-button>
      </el-radio-group>
    </div>

    <div class="filter-container">
      <el-input class="filter-item" v-model="listQuery.order_no" placeholder="请输入订单号" style="width: 250px;" clearable/>
<!--      <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="所属店铺" style="width: 200px" clearable>-->
<!--        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">-->
<!--        </el-option>-->
<!--      </el-select>-->
      <el-cascader ref="tree" class="filter-item" :options="schoolWithShops" :props="props" :show-all-levels="false" placeholder="所属食堂店铺" collapse-tags style="width: 250px" clearable @change="handleChangeShops"></el-cascader>
      <el-select class="filter-item" v-model="listQuery.sp_order_status" placeholder="分账订单状态" style="width: 140px" clearable>
        <el-option label="无需分账" :value="0"></el-option>
        <el-option label="待分账" :value="1"></el-option>
        <el-option label="处理中" :value="2"></el-option>
        <el-option label="分账完成" :value="3"></el-option>
        <el-option label="分账失败" :value="4"></el-option>
      </el-select>
      <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
      <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <div>
      <span style="font-size:18px;margin-bottom:10px;">订单总数：{{total}}</span>
      <span style="font-size:18px;margin-left:30px;margin-bottom:10px;">分账总额：{{total_ledger_money}}</span>
      <span style="font-size:18px;margin-left:30px;margin-bottom:10px;" v-for="(item,index) in ledger_role" :key="index">{{item.role_name}}：{{item.ledger_role_money}}</span>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="序号" width="60">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="订单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column label="分账单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sp_order_no }}
        </template>
      </el-table-column>
      <el-table-column label="店铺" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.shop.shop_name }}</div>
        </template>
      </el-table-column>
<!--      <el-table-column label="订单状态" min-width="80" align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <el-tag type="info" v-if="scope.row.order_status == 0">未接单</el-tag>-->
<!--          <el-tag type="info" v-else-if="scope.row.order_status == 1">已接单</el-tag>-->
<!--          <el-tag type="info" v-else-if="scope.row.order_status == 2">待评价</el-tag>-->
<!--          <el-tag type="success" v-else-if="scope.row.order_status == 3">已完成</el-tag>-->
<!--          <el-tag type="danger" v-else-if="scope.row.order_status == 4">已取消</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="分账订单状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.sp_order_status == 0">无需分账</el-tag>
          <el-tag type="info" v-else-if="scope.row.sp_order_status == 1">待分账</el-tag>
          <el-tag type="warning" v-else-if="scope.row.sp_order_status == 2">处理中</el-tag>
          <el-tag type="success" v-else-if="scope.row.sp_order_status == 3">分账完成</el-tag>
          <el-tag type="danger" v-else-if="scope.row.sp_order_status == 4">分账失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="分账错误原因" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sp_order_status===4?scope.row.sp_error_msg:'' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="下单时间" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column
              label="操作"
              fixed="right"
              align="center"
              width="230"
              class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
                  type="success"
                  size="mini"
                  @click="revenueLog(scope.row)"
          >分账收入记录</el-button
          >
          <el-button
                  type="primary"
                  size="mini"
                  @click="finishSPOrder(scope.row)"
                  :disabled="scope.row.pay_type !== 1 || (scope.row.sp_order_status !== 0 && scope.row.sp_order_status !== 4) || scope.row.is_finish_split === 1"
          >完结分账</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import AMapPosition from "@/components/AMapPosition";
import { mapGetters } from "vuex";

export default {
  components: {
    AMapPosition,
  },
  data() {
    return {
      shops: [],
      list: null,
      total: null,
      btnLoading: false,
      listLoading: true,
      loading: false,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        shop_id: "",
        shop_ids: [],
        order_no: "",
        sp_order_status: "",
        start_time: "",
        end_time: "",
        order_type: 1,
      },
      // 店铺筛选
      schoolWithShops: [],
      props: { multiple: true },
      total_ledger_money: null,
      ledger_role: null,
    };
  },
  created() {
    this.getList();
    this.getShopList();
    this.getSchoolWithShop();
  },
  computed: {
    ...mapGetters(["school_id"]),
  },
  watch: {
    "listQuery.order_type": {
      handler(newValue, oldValue) {
        this.listQuery.shop_ids = [];
        // 清空选中的节点
        this.$refs.tree.$refs.panel.clearCheckedNodes();
        // 设置为空可以让节点不高亮显示
        this.$refs.tree.$refs.panel.activePath = [];
        this.getShopList();
        this.getSchoolWithShop();
      },
      deep: true
    },
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getShopList();
      this.getSchoolWithShop();
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.listQuery.school_id = this.school_id;
      request({
        url: "/api/backend/school/spOrder",
        method: "post",
        data: this.listQuery,
        timeout: 6000000,
      }).then((response) => {
        this.list = response.data.ledger_order.data;
        this.total = response.data.ledger_order.total;
        this.total_ledger_money = response.data.total_ledger_money;
        this.ledger_role = response.data.ledger_role;
        this.listLoading = false;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          canteen_id: this.listQuery.canteen_id,
          shop_type: this.listQuery.order_type
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    getSchoolWithShop() {
      if (!this.school_id) {
        return;
      }
      request({
        url: "/api/backend/school/schoolWithShop",
        method: "get",
        params: {
          school_id: this.school_id,
          type: this.listQuery.order_type
        }
      }).then(response => {
        let data = response.data;
        let schoolWithShops = [];

        // 区分外卖与商城
        if (this.listQuery.order_type === 1) {
          data.forEach(element => {
            let obj = {
              value: element.id,
              label: element.school_name,
              children: []
            };
            element.canteens.forEach(element2 => {
              let obj2 = {
                value: element2.id,
                label: element2.canteen_name,
                children: []
              };
              element2.shops.forEach(element3 => {
                let obj3 = {
                  value: element3.id,
                  label: element3.shop_name,
                };
                obj2.children.push(obj3);
              });
              obj.children.push(obj2);
            });
            schoolWithShops.push(obj);
          });
        } else if(this.listQuery.order_type === 2) {
          data.forEach(element => {
            let obj = {
              value: element.id,
              label: element.school_name,
              children: []
            };
            element.shop_list.forEach(element2 => {
              let obj2 = {
                value: element2.id,
                label: element2.shop_name,
              };
              obj.children.push(obj2);
            });
            schoolWithShops.push(obj);
          });
        }

        this.schoolWithShops = schoolWithShops;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    revenueLog(item) {
      this.$router.push(`/school/revenueLog?order_no=${item.order_no}`);
    },
    finishSPOrder(item){
      request({
        url: "/api/backend/school/finishSPOrder",
        method: "post",
        data: {order_no: item.order_no},
      }).then(() => {
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      });
    },
    // 更新多选店铺
    handleChangeShops(data) {
      let shop_ids = [];
      if (data.length > 0) {
        // 区分外卖与商城
        if (this.listQuery.order_type === 1) {
          data.forEach(element => {
            shop_ids.push(element[2]);
          });
        } else if(this.listQuery.order_type === 2) {
          data.forEach(element => {
            shop_ids.push(element[1]);
          });
        }
      }
      this.listQuery.shop_ids = shop_ids;
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 140px;
  vertical-align: bottom;
}
.input {
  display: flex;
}
</style>
